import "../styles/globals.css";
import "../styles/globals-new.css";
import "../styles/product.css";
import "../styles/header.css";

import "../styles/about.css";
import "../styles/contact.css";
import "../styles/home.css";
import "../styles/rest.css";

import type {AppProps} from "next/app";
import {useMemo, useState} from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Provider} from "react-redux";
import {store} from "state/store";
import {DownloadModalContext} from "@utils/contexts";

const MyApp = ({Component, pageProps}: AppProps) => {
  const [queryClient] = useState(() => new QueryClient());

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const showDownloadValue = useMemo(() => {
    return {showDownloadModal, setShowDownloadModal};
  }, [showDownloadModal, setShowDownloadModal]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <DownloadModalContext.Provider value={showDownloadValue}>
          <Component {...pageProps} />
        </DownloadModalContext.Provider>
      </Provider>
    </QueryClientProvider>
  );
};

export default MyApp;
