import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";

export interface Lender {
  short_logo_url: string | undefined
  logo_url?: string | null
  minimum_tenor: string
  maximum_tenor: string
  tenor_type: string
  phone_number: string
  website: string
  email: string
  id: number
  org_name: string
  minimum_amount: string
  maximum_amount: string
  currency: string
  lender_type: string
  web_app_url?: string
  facebook_url?: string
  twitter_url?: string
  whatsapp_url?: string
  lender_category: string
  is_lender_spotlight: string
  countries: string
  country: string
  requirements: string
  overall_rating: string
  app_store_url: string
  playstore_url: string
  about: any
  // Add other properties as needed
}

interface LendersState {
  lenders: {id: number; attributes: Lender}[]
  status: "idle" | "loading" | "succeeded" | "failed"
  error: string | null
  total: number
  currentPage: number
  totalPages: number
  perPage: number
  // sortBy: string
}

const initialState: LendersState = {
  lenders: [],
  status: "idle",
  error: null,
  total: 0,
  perPage: 12,
  currentPage: 1,
  totalPages: 0,
  // sortBy: "Most popular",
};

export const fetchLenders = createAsyncThunk(
  "lenders/fetchLenders",
  async (_, thunkAPI) => {
    // Check if lenders data is available in sessionStorage
    const storedLenders = sessionStorage.getItem("lenders");
    if (storedLenders) {
      return JSON.parse(storedLenders);
    } else {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/lenders?populate=*&pagination[page]=1&pagination[pageSize]=1000`,
          {
            headers: {
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
            },
          }
        );
        sessionStorage.setItem("lenders", JSON.stringify(response.data.data));
        return response.data.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue({error: error.message});
      }
    }
  }
);

const allLendersSlice = createSlice({
  name: "lenders",
  initialState,
  reducers: {
    loadMore: (state) => {
      state.perPage += 12;
    },
    calculateLenders: (state) => {
      state.status = "loading";
    },
    releaseLenders: (state) => {
      state.status = "succeeded";
    },
    // sortList: (state, action: PayloadAction<string>) => {
    //   state.sortBy = action.payload;
    // },
    filterLenders: (state, action: PayloadAction<any>) => {
      state.lenders = action.payload;
    },
    searchLenders: (state, action) => {
      const storedData = sessionStorage.getItem("lenders");

      if (storedData) {
        const providerData = JSON.parse(storedData);

        if (action.payload.length === 0) {
          state.lenders = providerData;
        } else {
          const filterDataByName = providerData.filter((item: any) =>
            item.attributes.org_name
              .toLowerCase()
              .includes(action.payload.toLowerCase())
          );

          state.lenders = filterDataByName;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLenders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLenders.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        state.lenders = action.payload;
        state.total = action.payload.length;
      })
      .addCase(fetchLenders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch offers";
      });
  },
});

export const {
  calculateLenders,
  releaseLenders,
  loadMore,
  // sortList,
  filterLenders,
  searchLenders,
} = allLendersSlice.actions;

export default allLendersSlice.reducer;
