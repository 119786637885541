import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";

export interface Provider {
  id: string
  provider: string
  country: string
  services: string
  phone_number: string
  email: string
  website: string
  whatsapp_url: string
  facebook_url: string
  twitter_url: string
  full_logo: any
  short_logo_url: string
  service_type: string
  countries: string[]
  category_type: string
  address: string
  instagram_url: string
  about: any
}

interface AllProvidersState {
  providers: {id: number; attributes: Provider}[]
  status: "idle" | "loading" | "succeeded" | "failed"
  error: string | null
  perPage: number
  total: number
}

const initialState: AllProvidersState = {
  providers: [],
  status: "idle",
  error: null,
  perPage: 12,
  total: 0,
};

// Define the async thunk
export const fetchProviders = createAsyncThunk<
  Provider[],
  void,
  {rejectValue: {error: string}}
>("allProviders/fetchProviders", async (_, thunkAPI) => {
  const storedData = sessionStorage.getItem("providers");
  if (storedData) {
    return JSON.parse(storedData);
  } else {
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/project-x-providers?populate=*&pagination[page]=1&pagination[pageSize]=1000`,
        {
          headers: {
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
          },
        }
      );
      sessionStorage.setItem("providers", JSON.stringify(response.data.data));
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
});

// Create the slice
const allProvidersSlice = createSlice({
  name: "allProviders",
  initialState,
  reducers: {
    loadMore: (state) => {
      state.perPage += 12;
    },
    // NOTE - Custon loader to filter the list with calculator data
    calculateProviders: (state) => {
      state.status = "loading";
    },
    releaseProviders: (state) => {
      state.status = "succeeded";
    },
    searchProviders: (state, action) => {
      const storedData = sessionStorage.getItem("providers");

      if (storedData) {
        const providerData = JSON.parse(storedData);

        if (action.payload.length === 0) {
          state.providers = providerData;
        } else {
          const filterDataByName = providerData.filter(
            (item: any) =>
              item.attributes.provider
                .toLowerCase()
                .includes(action.payload.toLowerCase()) ||
              item.attributes.service_type
                .toLowerCase()
                .includes(action.payload.toLowerCase())
          );

          state.providers = filterDataByName;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchProviders.fulfilled,
        // TODO - Update interface for response
        (state, action: PayloadAction<any>) => {
          // console.log("Providers in redux", action.payload);

          state.status = "succeeded";
          state.providers = action.payload;
          state.total = action.payload.length;
        }
      )
      .addCase(
        fetchProviders.rejected,
        (state, action: PayloadAction<{error: string} | undefined>) => {
          state.status = "failed";
          state.error = action.payload?.error ?? "Failed to fetch providers";
        }
      );
  },
});

export const {calculateProviders, releaseProviders, loadMore, searchProviders} =
  allProvidersSlice.actions;

export default allProvidersSlice.reducer;
