import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {updateCorrelation} from "@utils/formatAmount";
import Router from "next/router";

export interface ProvidersFilterState {
  country: string[]
  serviceType: string[]
  selected_provider: string | null
  url?: string
  orderBy: string
  correlation: {country?: number[]; serviceType?: number[]}
  searchTotal: number
}

const setCorrelationToSessionStorage = (correlation: {
  country?: number[]
  serviceType?: number[]
}) => {
  sessionStorage.setItem("provider_correlation", JSON.stringify(correlation));
};

const initialState: ProvidersFilterState = {
  country: [],
  serviceType: [],
  selected_provider: "",
  orderBy: "Most popular",
  correlation: {country: [], serviceType: []},
  searchTotal: 0,
};

const updateURLParams = (state: ProvidersFilterState) => {
  const params = new URLSearchParams();
  if (state.country.length > 0) params.set("country", state.country.join(","));
  if (state.serviceType.length > 0)
    params.set("service_type", state.serviceType.join(","));
  if (state.selected_provider)
    params.set("selected_provider", state.selected_provider);
  const newURL = `${state.url || window.location.pathname}?${params.toString()}`;
  window.history.pushState({}, "", newURL);
  Router.push(newURL);
};

const loadFromURLParams = (state: ProvidersFilterState) => {
  const params = new URLSearchParams(window.location.search);
  const country = params.get("country");
  const serviceType = params.get("service_type");
  const selected_provider = params.get("selected_provider");

  if (country) state.country = country.split(",");
  if (serviceType) state.serviceType = serviceType.split(",");
  if (selected_provider) state.selected_provider = selected_provider;
};

const clearURLParams = () => {
  const newURL = `${window.location.pathname}`;
  window.history.pushState({}, "", newURL);
};

const providersFilterSlice = createSlice({
  name: "providersFilter",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<Partial<ProvidersFilterState>>) {
      Object.assign(state, action.payload);
      updateURLParams(state);
    },
    loadFilter(state) {
      loadFromURLParams(state);
    },
    clearFilters: () => {
      clearURLParams();
      return initialState; // Reset filter state to initial state
    },
    setTotal(state, action: PayloadAction<number>) {
      state.searchTotal = action.payload;
    },
    setCorrelation(
      state,
      action: PayloadAction<Partial<{country: number[]; serviceType: number[]}>>
    ) {
      // console.log("bad human", action.payload);

      if (state.correlation) {
        if (action.payload.country) {
          state.correlation.country = action.payload.country;

          // console.log("updates", state.correlation.country);
        }
        if (action.payload.serviceType) {
          state.correlation.serviceType = action.payload.serviceType;

          // console.log("updates", state.correlation.serviceType);
        }

        // console.log("good human", state.correlation);
        setCorrelationToSessionStorage(state.correlation);
      } else {
        state.correlation = {
          country: action.payload.country || [],
          serviceType: action.payload.serviceType || [],
        };
        setCorrelationToSessionStorage(state.correlation);
      }
    },
  },
});

export const {setFilter, loadFilter, clearFilters, setCorrelation, setTotal} =
  providersFilterSlice.actions;
export default providersFilterSlice.reducer;
