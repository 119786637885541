import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createAction,
} from "@reduxjs/toolkit";
import axios from "axios";

export interface Offer {
  product_id: number
  org_id: number
  lender: string
  product_name: string
  product_description: string
  minimum_amount: number
  maximum_amount: number
  currency: string
  minimum_tenor: number
  maximum_tenor: number
  tenor_type: string
  phone_number: string
  email: string
  website: string | null
  full_logo?: any
  short_logo?: any
  logo_url: string
  public_key: string
  process_time: string
  country: string
  requirements: string[]
  country_code: string
  lender_type: string
  lender_category: string
  is_product_spotlight: string
}

// offersSlice.ts
interface OffersState {
  offers: {id: number; attributes: Offer}[]
  status: "idle" | "loading" | "succeeded" | "failed"
  error: string | null
  currentPage: number
  totalPages: number
  total: number
  perPage: number
}

const initialState: OffersState = {
  offers: [],
  status: "idle",
  error: null,
  currentPage: 1,
  totalPages: 0,
  total: 0,
  perPage: 12,
};

// Define an action creator for loadMore
export const loadMore = createAction("offers/loadMore");

// Action creator for setting loading state
const setLoading = createAction("offers/setLoading");

// Action creator for setting succeeded state after a delay
const setSucceededAfterDelay = createAction("offers/setSucceededAfterDelay");

export const fetchOffers = createAsyncThunk(
  "offers/fetchOffers",
  async (_, thunkAPI) => {
    // Check if lenders data is available in sessionStorage
    const storedLenders = sessionStorage.getItem("offers");
    if (storedLenders) {
      return JSON.parse(storedLenders);
    } else {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/project-x-loan-offers?populate=*&pagination[page]=1&pagination[pageSize]=1000`,
          {
            headers: {
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
            },
          }
        );
        sessionStorage.setItem("offers", JSON.stringify(response.data.data));
        return response.data.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue({error: error.message});
      }
    }
  }
);

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    resetOffers: (state) => {
      state.offers = [];
      state.currentPage = 1;
      state.totalPages = 1;
      state.status = "idle";
      state.error = null;
    },
    loadMore: (state) => {
      state.perPage += 12;
    },
    // NOTE - Custon loader to filter the list with calculator data
    calculateOffers: (state) => {
      state.status = "loading";
    },
    releaseOffers: (state) => {
      state.status = "succeeded";
    },
    // sortList: (state, action: PayloadAction<string>) => {
    //   state.sortBy = action.payload;
    // },
    filterOffers: (
      state,
      action: PayloadAction<{id: number; attributes: Offer}[]>
    ) => {
      // console.log("Filtering offers", action.payload);
      state.offers = action.payload;
    },
    searchOffers: (state, action) => {
      // console.log("search term", action.payload);
      const storedData = sessionStorage.getItem("offers");

      if (storedData) {
        const providerData = JSON.parse(storedData);

        if (action.payload.length === 0) {
          state.offers = providerData;
        } else {
          const filterDataByName = providerData.filter(
            (item: Offer) =>
              item.lender
                .toLowerCase()
                .includes(action.payload.toLowerCase()) ||
              item.lender_type
                .toLowerCase()
                .includes(action.payload.toLowerCase())
          );

          state.offers = filterDataByName;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOffers.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        state.offers = action.payload;
        state.total = action.payload.length;
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch offers";
      });
  },
});

export const {
  resetOffers,
  calculateOffers,
  releaseOffers,
  // sortList,
  filterOffers,
  searchOffers,
} = offersSlice.actions;

export default offersSlice.reducer;

// Middleware function for handling the delay
export const delayMiddleware = () => (next: any) => (action: any) => {
  if (action.type === "offers/loadMore") {
    // Dispatch setLoading action immediately
    next(setLoading());
    // Dispatch setSucceededAfterDelay action after 3 seconds
    setTimeout(() => {
      next(setSucceededAfterDelay());
    }, 3000);
  }
  // Pass the action to the next middleware or reducer
  return next(action);
};
